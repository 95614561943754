import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';


const MembersAreaPage = (): ReactElement => (

  <Layout>
        <Intro
      title={
        <> Welcome to the Members Area! </>
      }
      subTitle={<>Under construction, Please come again later.</>}
      
    />
  </Layout>
);

export default MembersAreaPage;
